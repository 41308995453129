var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-select"},[_c('div',{staticClass:"area-col"},[_c('div',{staticClass:"col-header"},[_c('span',{staticClass:"col-header-title"},[_vm._v(_vm._s(_vm.titleArr[0]))]),_c('pop-checkbox',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowChecked && !_vm.disabled),expression:"isShowChecked && !disabled"}],attrs:{"id":_vm.tag + 'checkbox_province_all'},model:{value:(_vm.allProvinceFlag),callback:function ($$v) {_vm.allProvinceFlag=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"allProvinceFlag"}},[_vm._v(" "+_vm._s('All')+" ")])],1),_c('pop-scroll',{staticClass:"col-body",style:(_vm.scrollStyle),attrs:{"id":_vm.id + '_province_col',"scroll-height":_vm.scrollheight,"bar-opacity":0}},[_c('ul',_vm._l((_vm.provinceList),function(item,index){return _c('li',{key:'li_province_' + index,staticClass:"area-item",class:{
            active: item.value == _vm.currentSelectProvince.value,
            disabled: _vm.disabled
          },on:{"click":function($event){return _vm.selectProvince(item)}}},[_c('div',{staticClass:"checkbox-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.checkedProvinceArr),expression:"checkedProvinceArr",modifiers:{"trim":true}}],staticClass:"checkbox",attrs:{"id":'cbx_province_' + item.value + _vm.tag,"type":"checkbox","half-checked":item.halfChecked,"disabled":item.disabled},domProps:{"value":item,"checked":item.checked,"checked":Array.isArray(_vm.checkedProvinceArr)?_vm._i(_vm.checkedProvinceArr,item)>-1:(_vm.checkedProvinceArr)},on:{"click":function($event){return _vm.checkOrUncheckSingleProvince(item)},"change":function($event){var $$a=_vm.checkedProvinceArr,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedProvinceArr=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedProvinceArr=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedProvinceArr=$$c}}}}),_c('label',{staticClass:"checkbox-label",attrs:{"for":'cbx_province_' + item.value + _vm.tag}})]),_c('label',{staticClass:"area-label",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])])}),0)])],1),_c('div',{staticClass:"area-col"},[_c('div',{staticClass:"col-header"},[_c('span',{staticClass:"col-header-title"},[_vm._v(_vm._s(_vm.titleArr[1]))]),_c('pop-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],attrs:{"id":_vm.tag + 'checkbox_city_all'},model:{value:(_vm.allCityFlag),callback:function ($$v) {_vm.allCityFlag=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"allCityFlag"}},[_vm._v(" "+_vm._s('All')+" ")])],1),_c('pop-scroll',{staticClass:"col-body",style:(_vm.scrollStyle),attrs:{"id":_vm.id + '_city_col',"scroll-height":_vm.scrollheight,"bar-opacity":0}},[_c('ul',_vm._l((_vm.cityList),function(item,index){return _c('li',{key:'li_city_' + index,staticClass:"area-item",class:{
            active: item.value == _vm.currentSelectCity.value,
            disabled: _vm.disabled
          },on:{"click":function($event){return _vm.selectCity(item)}}},[_c('div',{staticClass:"checkbox-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.checkedCityArr),expression:"checkedCityArr",modifiers:{"trim":true}}],staticClass:"checkbox",attrs:{"id":'cbx_city_' + item.value + _vm.tag,"type":"checkbox","half-checked":item.halfChecked,"disabled":item.disabled},domProps:{"value":item,"checked":item.checked,"checked":Array.isArray(_vm.checkedCityArr)?_vm._i(_vm.checkedCityArr,item)>-1:(_vm.checkedCityArr)},on:{"click":function($event){return _vm.checkOrUncheckSingleCity(item)},"change":function($event){var $$a=_vm.checkedCityArr,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedCityArr=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedCityArr=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedCityArr=$$c}}}}),_c('label',{staticClass:"checkbox-label",attrs:{"for":'cbx_city_' + item.value + _vm.tag}})]),_c('label',{staticClass:"area-label",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])])}),0)])],1),_c('div',{staticClass:"area-col"},[_c('div',{staticClass:"col-header"},[_c('span',{staticClass:"col-header-title"},[_vm._v(_vm._s(_vm.titleArr[2]))]),_c('pop-checkbox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],attrs:{"id":_vm.tag + 'checkbox_area_all'},model:{value:(_vm.allAreaFlag),callback:function ($$v) {_vm.allAreaFlag=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"allAreaFlag"}},[_vm._v(" "+_vm._s('All')+" ")])],1),_c('pop-scroll',{staticClass:"col-body",style:(_vm.scrollStyle),attrs:{"id":_vm.id + '_area_col',"scroll-height":_vm.scrollheight,"bar-opacity":0}},[_c('ul',_vm._l((_vm.areaList),function(item,index){return _c('li',{key:'li_area_' + index,staticClass:"area-item",class:{
            active: item.value == _vm.currentSelectArea.value,
            disabled: _vm.disabled
          },on:{"click":function($event){return _vm.selectArea(item)}}},[_c('div',{staticClass:"checkbox-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.checkedAreaArr),expression:"checkedAreaArr",modifiers:{"trim":true}}],staticClass:"checkbox",attrs:{"id":'cbx_area_' + item.value + _vm.tag,"type":"checkbox","disabled":item.disabled},domProps:{"value":item,"checked":item.checked,"checked":Array.isArray(_vm.checkedAreaArr)?_vm._i(_vm.checkedAreaArr,item)>-1:(_vm.checkedAreaArr)},on:{"click":function($event){return _vm.checkOrUncheckSingleArea(item)},"change":function($event){var $$a=_vm.checkedAreaArr,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedAreaArr=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedAreaArr=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedAreaArr=$$c}}}}),_c('label',{staticClass:"checkbox-label",attrs:{"for":'cbx_area_' + item.value + _vm.tag}})]),_c('label',{staticClass:"area-label",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])])}),0)])],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }