<template>
  <div class="address-select">
    <div class="area-col">
      <div class="col-header">
        <span class="col-header-title">{{ titleArr[0] }}</span>
        <pop-checkbox
          v-show="isShowChecked && !disabled"
          :id="tag + 'checkbox_province_all'"
          v-model.trim="allProvinceFlag"
        >
          {{ 'All' }}
        </pop-checkbox>
      </div>
      <pop-scroll
        :id="id + '_province_col'"
        class="col-body"
        :style="scrollStyle"
        :scroll-height="scrollheight"
        :bar-opacity="0"
      >
        <ul>
          <li
            v-for="(item, index) in provinceList"
            :key="'li_province_' + index"
            class="area-item"
            :class="{
              active: item.value == currentSelectProvince.value,
              disabled: disabled
            }"
            @click="selectProvince(item)"
          >
            <div class="checkbox-wrapper">
              <input
                :id="'cbx_province_' + item.value + tag"
                v-model.trim="checkedProvinceArr"
                type="checkbox"
                class="checkbox"
                :value="item"
                :checked="item.checked"
                :half-checked="item.halfChecked"
                :disabled="item.disabled"
                @click="checkOrUncheckSingleProvince(item)"
              >
              <label
                class="checkbox-label"
                :for="'cbx_province_' + item.value + tag"
              />
            </div>
            <label class="area-label" :title="item.name">{{ item.name }}</label>
          </li>
        </ul>
      </pop-scroll>
    </div>
    <div class="area-col">
      <div class="col-header">
        <span class="col-header-title">{{ titleArr[1] }}</span>
        <pop-checkbox
          v-show="!disabled"
          :id="tag + 'checkbox_city_all'"
          v-model.trim="allCityFlag"
        >
          {{ 'All' }}
        </pop-checkbox>
      </div>
      <pop-scroll
        :id="id + '_city_col'"
        class="col-body"
        :style="scrollStyle"
        :scroll-height="scrollheight"
        :bar-opacity="0"
      >
        <ul>
          <li
            v-for="(item, index) in cityList"
            :key="'li_city_' + index"
            class="area-item"
            :class="{
              active: item.value == currentSelectCity.value,
              disabled: disabled
            }"
            @click="selectCity(item)"
          >
            <div class="checkbox-wrapper">
              <input
                :id="'cbx_city_' + item.value + tag"
                v-model.trim="checkedCityArr"
                type="checkbox"
                class="checkbox"
                :value="item"
                :checked="item.checked"
                :half-checked="item.halfChecked"
                :disabled="item.disabled"
                @click="checkOrUncheckSingleCity(item)"
              >
              <label
                class="checkbox-label"
                :for="'cbx_city_' + item.value + tag"
              />
            </div>
            <label class="area-label" :title="item.name">{{ item.name }}</label>
          </li>
        </ul>
      </pop-scroll>
    </div>
    <div class="area-col">
      <div class="col-header">
        <span class="col-header-title">{{ titleArr[2] }}</span>
        <pop-checkbox
          v-show="!disabled"
          :id="tag + 'checkbox_area_all'"
          v-model.trim="allAreaFlag"
        >
          {{ 'All' }}
        </pop-checkbox>
      </div>
      <pop-scroll
        :id="id + '_area_col'"
        class="col-body"
        :style="scrollStyle"
        :scroll-height="scrollheight"
        :bar-opacity="0"
      >
        <ul>
          <li
            v-for="(item, index) in areaList"
            :key="'li_area_' + index"
            class="area-item"
            :class="{
              active: item.value == currentSelectArea.value,
              disabled: disabled
            }"
            @click="selectArea(item)"
          >
            <div class="checkbox-wrapper">
              <input
                :id="'cbx_area_' + item.value + tag"
                v-model.trim="checkedAreaArr"
                type="checkbox"
                class="checkbox"
                :value="item"
                :checked="item.checked"
                :disabled="item.disabled"
                @click="checkOrUncheckSingleArea(item)"
              >
              <label
                class="checkbox-label"
                :for="'cbx_area_' + item.value + tag"
              />
            </div>
            <label class="area-label" :title="item.name">{{ item.name }}</label>
          </li>
        </ul>
      </pop-scroll>
    </div>
    <slot />
  </div>
</template>
<script type="text/ecmascript-6">
// import util from '../../../../src/assets/js/tools/util';

export default {
  name: 'SellerSelect',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    popButton: resolve => require(['../button/PopButton'], resolve),
    popCheckbox: resolve => require(['../checkbox/PopCheckbox'], resolve),
    popScroll: resolve => require(['../scrollbar/PopScrollbar'], resolve)
  },
  props: {
    id: {
      type: String,
      default: 'defaultId'
    },
    provinceList: {
      type: Array,
      default () {
        return [];
      }
    },
    title: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 280
    },
    isShowChecked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: ''
    },
    titleArr: {
      type: Array,
      default () {
        let that = this;
        return [that.provinceTitle, that.cityTitle, that.areaTitle];
      }
    }
  },
  data () {
    return {
      // 已经选择的数据
      checkedProvinceArr: [],
      checkedCityArr: [],
      checkedAreaArr: [],
      // 当前选择的节点
      currentSelectProvince: {},
      currentSelectCity: {},
      currentSelectArea: {}
    };
  },
  computed: {
    cityList () {
      return this.currentSelectProvince ? (this.currentSelectProvince.childList || []) : [];
    },
    areaList () {
      return this.currentSelectCity ? (this.currentSelectCity.childList || []) : [];
    },
    allProvinceFlag: {
      get () {
        return this.isAllChecked(this.provinceList);
      },
      set (checked) {
        this.checkOrUncheckAllProvince(checked);
      }
    },
    allCityFlag: {
      get () {
        return this.isAllChecked(this.cityList);
      },
      set (checked) {
        this.checkOrUncheckAllCity(this.cityList, checked);
      }
    },
    allAreaFlag: {
      get () {
        return this.isAllChecked(this.areaList);
      },
      set (checked) {
        this.checkOrUncheckAllArea(this.areaList, checked);
      }
    },
    scrollheight () {
      return this.height - 40;
    },
    scrollStyle () {
      return {
        height: this.height - 30 + 'px'
      };
    }
  },
  watch: {
    cityList (newVal) {
      if (!newVal || newVal.length < 1) {
        return;
      }
      let selectCity = newVal.find((item) => {
        return item.checked;
      });
      this.currentSelectCity = selectCity || newVal[0];
    },
    areaList (newVal) {
      if (!newVal || newVal.length < 1) {
        return;
      }
      let selectArea = newVal.find((item) => {
        return item.checked;
      });
      this.currentSelectArea = selectArea || newVal[0];
    },
    checkedCityArr () {
      if (this.isAllUnchecked(this.cityList)) {
        this.checkAndModifyCheckArr(this.checkedProvinceArr, this.provinceList, this.cityList[0].pValue, false, false);
      } else {
        let halfChecked = !this.isAllChecked(this.cityList);
        this.checkAndModifyCheckArr(this.checkedProvinceArr, this.provinceList, this.cityList[0].pValue, true, halfChecked);
      }
    },
    checkedAreaArr () {
      let self = this;
      if (self.isAllUnchecked(self.areaList)) {
        self.checkAndModifyCheckArr(self.checkedCityArr, self.cityList, self.areaList[0].pValue, false, false);
      } else {
        let halfChecked = !self.isAllChecked(self.areaList);
        self.checkAndModifyCheckArr(self.checkedCityArr, self.cityList, self.areaList[0].pValue, true, halfChecked);
      }
      // 防抖
      // util.debounce(this.submit(), 500);
      this.submit()
    },
    checkedProvinceArr(){
      if(this.checkedProvinceArr.length>1){
        return this.$message({message:'Please uncheck the item'})
       
      }
    }
  },
  mounted () {
    // 初始化选择对象
    this.initCurrentSelectObj();
    // 初始化默认值
    this.initDefaultData();
  },
  methods: {
    submit () {
      this.$nextTick(() => {
        let allSelect = this.allProvinceFlag && this.allCityFlag && this.allAreaFlag;
        this.$emit('change', [this.checkedProvinceArr, this.checkedCityArr, this.checkedAreaArr], allSelect);
      });
    },
    selectProvince (item) {
     
      this.currentSelectProvince = item;

      
    },
    selectCity (item) {
      this.currentSelectCity = item;
    },
    selectArea (item) {
      this.currentSelectArea = item;
    },
    // 判断列表是否全部选中
    isAllChecked (list) {
      for (let i = 0, length = list.length; i < length; i++) {
        if (!list[i].checked) {
          return false;
        }
      }
      return true;
    },
    // 判断列表是否全部未选中
    isAllUnchecked (list) {
      for (let i = 0, length = list.length; i < length; i++) {
        if (list[i].checked) {
          return false;
        }
      }
      return true;
    },
    // 勾选或取消勾选全部
    checkOrUncheckAll (list, checked) {
      if (list && list.length > 0) {
        list.forEach(item => {
          item.checked = checked;
        });
      }
    },
    checkOrUncheckAllProvince (checked) {
      this.checkOrUncheckAll(this.provinceList, checked);
      this.checkedProvinceArr = this.changeCheckedArrVal(this.checkedProvinceArr, this.provinceList, checked);
      for (let item of this.provinceList) {
        this.checkOrUncheckAllCity(item.childList, checked);
      }
    },
    checkOrUncheckAllCity (cityList, checked) {
      this.checkOrUncheckAll(cityList, checked);
      this.checkedCityArr = this.changeCheckedArrVal(this.checkedCityArr, cityList, checked);
      for (let item of cityList) {
        this.checkOrUncheckAllArea(item.childList, checked);
      }
    },
    checkOrUncheckAllArea (areaList, checked) {
      this.checkOrUncheckAll(areaList, checked);
      this.checkedAreaArr = this.changeCheckedArrVal(this.checkedAreaArr, areaList, checked);
    },
    checkOrUncheckSingleProvince (item) {
      // console.log('level1=====',item)
      if(this.checkedProvinceArr.length<1){
        let halfChecked = item.halfChecked || false;
        if (halfChecked) {
          item.halfChecked = false;
          const arr = this.checkedProvinceArr;
          this.checkedProvinceArr = false;
          this.$nextTick(() => {
            this.checkedProvinceArr = arr;
          });
        } else {
          item.checked = !item.checked;
        }
        this.checkOrUncheckAllCity(item.childList, item.checked);
      }
      
    },
    checkOrUncheckSingleCity (item) {
      let halfChecked = item.halfChecked || false;
      if (halfChecked) {
        item.halfChecked = false;
        const arr = this.checkedCityArr;
        this.checkedCityArr = false;
        this.$nextTick(() => {
          this.checkedCityArr = arr;
        });
      } else {
        item.checked = !item.checked;
      }
      this.checkOrUncheckAllArea(item.childList, item.checked);
    },
    checkOrUncheckSingleArea (item) {
      item.checked = !item.checked;
    },
    /**
         * 检查某个key的元素在checkedArr中是否存，然后根据val来加入或者移除
         * @param checkedArr 选择的数组
         * @param baseArr 基础数组
         * @param checkValue 检查的值
         * @param checked true表示如果在选择数组中不存在则在基础数组中找到对应值的对象加入，false表示如果存在就从中删除
         */
    checkAndModifyCheckArr (checkedArr, baseArr, checkValue, checked, halfChecked) {
      if (!checkedArr || !checkedArr.findIndex) {
        return false;
      }
      let index = checkedArr.findIndex(item => item && item.value === checkValue);
      let obj = baseArr.find(temp => temp && temp.value === checkValue);
      if (obj) {
        obj.checked = checked;
        obj.halfChecked = halfChecked;
        if (checked && index < 0) { // 如果选择,但是在checkArr中不存在，就加入
          checkedArr.push(obj);
        }
        if (!checked && index > -1) { // 如果取消选择,且在checkArr中存在，则移除
          checkedArr.splice(index, 1);
        }
      }
    },
    /**
         * 修改选择数组，
         * @param checkedArr 被修改的数组
         * @param baseArr 基础数组
         * @param changeFlag true表示将基础数组加入选择数组去重，false标识将基础数组冲修改数组中移除
         * @returns {*}
         */
    changeCheckedArrVal (checkedArr, baseArr, changeFlag) {
      if (!baseArr) {
        return checkedArr;
      }
      // 如果是全选，将基础数据加入选择数据，去重
      if (changeFlag) {
        checkedArr = checkedArr.concat(baseArr);
        checkedArr = Array.from(new Set(checkedArr));
      } else {
        // 取消全选，将基础数据从选择数据中移除
        for (let item of baseArr) {
          if (item.checked) {
            continue;
          }
          let index = checkedArr.findIndex(checkedObj => checkedObj.value === item.value);
          if (index > -1) {
            checkedArr.splice(index, 1);
          }
        }
      }
      return checkedArr;
    },
    // 初始化当前选择的对象
    initCurrentSelectObj () {
      let checkedProvince = this.provinceList.find(item => item.checked);
      checkedProvince = checkedProvince || this.provinceList[0];
      this.currentSelectProvince = checkedProvince;
      let checkedCity = checkedProvince && checkedProvince.childList ? checkedProvince.childList.find(item => item.checked) : null;
      checkedCity = checkedCity || (checkedProvince && checkedProvince.childList ? checkedProvince.childList[0] : null);
      this.currentSelectCity = checkedCity;
      let checkedArea = checkedCity && checkedCity.childList ? checkedCity.childList.find(item => item.checked) : null;
      checkedArea = checkedArea || (checkedCity && checkedCity.childList ? checkedCity.childList[0] : null);
      this.currentSelectArea = checkedArea;
    },
    // 初始化默认值信息
    initDefaultData () {
      let self = this;
      if (!self.provinceList || self.provinceList.length < 1) {
        return;
      }
      for (let province of self.provinceList) {
        // 将选择的默认值丢到选择对象
        if (province.checked) {
          self.checkedProvinceArr.push(province);
        }
        if (province.childList) {
          // 遍历市信息
          for (let city of province.childList) {
            // 将选择的市丢到选择对象
            if (city.checked) {
              self.checkedCityArr.push(city);
            }
            if (city.childList) {
              // 遍历区信息
              for (let area of city.childList) {
                // 将选择的区信息丢到选择的对象
                if (area.checked) {
                  self.checkedAreaArr.push(area);
                }
              }
            }
          }
        }
      }
    },
    clearSelect () {
      this.recursion(this.provinceList);
      this.checkedProvinceArr = [];
      this.checkedCityArr = [];
      this.checkedAreaArr = [];
    },
    recursion (lists) {
      lists.forEach((item) => {
        if (item.checked) {
          item.checked = false;
        }
        if (item.childList) {
          this.recursion(item.childList);
        }
      });
    }
  }
};
</script>
<style lang="stylus" rel="stylesheet/stylus">
*{
  list-style:none
}
.address-select
    width 100%;
    height 1000px;
    font-size 0;
    .area-col
        background:rgba(255,255,255,1)
        width: 30%;
        margin:10px 10px
        border: 1px solid rgba(0, 0, 0, .08);
        display inline-block;
        color: rgba(0, 0, 0, .65);
        vertical-align top
        font-size 14px
        border-radius 4px
        & + .area-col
            // margin-left 20px;
        .col-header
            padding 8px 14px;
            height 38px;
            text-align right
            .col-header-title
                float left
                display inline-block
                line-height 20px
        .col-body
            padding 0
            width: 100%
            overflow: hidden
            .area-item
                padding 0 5px
                white-space: nowrap
                &.active, &:hover
                    background white;
                    // color #2D84F5
                &.active.disabled, &.disabled:hover
                    background #F1F4F7;
                    color #676975
                .area-checkbox
                    vertical-align top
                    line-height 30px;
                    color #2D84F5
                .area-label
                    line-height 30px;
                    display inline-block;
                    width 200px;
                    cursor pointer
</style>
